<template>
  <v-main>
    <v-container class="grey lighten-5 pa-0 align-stretch d-flex" style="height:100%" fluid>
      <v-row no-gutters>
        <v-col class="col-12 col-md-6 col-lg-4 align-center d-flex justify-center">
          <div style="width:90%;text-align:left;">
            <h1 class="text-h3">Registreeri</h1>
            <validation-observer ref="observer" v-slot="{}">
              <form class="mt-10" @submit.prevent="submit">
                <validation-provider  v-slot="{ errors }" name="E-posti aadress" rules="required|email">
                  <v-text-field v-model="registerForm.email" :error-messages="errors" label="E-posti aadress" required outlined />
                </validation-provider>
                <validation-provider  v-slot="{ errors }" name="Parool" rules="required">
                  <v-text-field v-model="registerForm.password" type="password" :error-messages="errors" label="Parool" required outlined />
                </validation-provider>
                <validation-provider  v-slot="{ errors }" name="Nimi" rules="required">
                  <v-text-field v-model="registerForm.name" :error-messages="errors" label="Nimi" required outlined />
                </validation-provider>
                <v-btn class="mt-3" type="submit" block large color="primary" tile>Edasi</v-btn>
              </form>
            </validation-observer>
            <div class="mt-10">
              <p>Kui sul on juba konto, siis <a @click="login" href="#"><strong>logi sisse</strong></a>.</p>
            </div>
          </div>
        </v-col>
        <v-col class="bg"></v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      registerForm: {
        email: '',
        password: '',
        name: ''
      }
    }
  },
  components: {
  },
  methods: {
    ...mapActions(['showNotification']),
    submit () {
      this.$http.post('/api/register', { ...this.registerForm }).then(() => {
        this.showNotification({ message: 'Konto on registreeritud', type: 'success' });
        this.$router.push({ name: 'login' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' });
      })
    },
    login (e) {
      this.$router.push({ name: 'login' })
      e.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background:#FFF url('../assets/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>