<template>
  <v-main>
    <v-container class="grey lighten-5 pa-0 align-stretch d-flex" style="height:100%" fluid>
      <v-row no-gutters>
        <v-col class="col-12 col-md-6 col-lg-4 align-center d-flex justify-center">
          <div style="width:90%;text-align:left;">
            <h1 class="text-h3 mb-10">Unustasin parooli</h1>
            <div v-if="!showResetForm">
              <validation-observer ref="observer" v-slot="{}">
                <form class="mt-10" @submit.prevent="createForgot">
                  <validation-provider  v-slot="{ errors }" name="email" rules="required">
                    <v-text-field v-model="forgotForm.email" :error-messages="errors" label="E-maili aadress" required outlined />
                  </validation-provider>
                  <v-btn class="mt-3 mb-10" type="submit" block large color="secondary" tile>Saada</v-btn>
                </form>
              </validation-observer>
            </div>
            <div v-else>
              <validation-observer ref="observer" v-slot="{}">
                <form class="mt-10" @submit.prevent="passwordSubmit">
                  <ValidationProvider v-slot="{ errors }" name="Parool">
                    <v-text-field type="password" v-model="passwordForm.password" :error-messages="errors" label="Uus parool" required outlined />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="Parooli kordus">
                    <v-text-field type="password" v-model="passwordForm.passwordRepeat" :error-messages="errors" label="Parooli kordus" required outlined />
                  </ValidationProvider>
                  <v-btn class="mt-3 mb-10" type="submit" block large color="secondary" tile>Saada</v-btn>
                </form>
              </validation-observer>
            </div>
          </div>
        </v-col>
        <v-col class="bg"></v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      resetToken: null,
      showResetForm: false,
      forgotForm: {
        email: ''
      },
      passwordForm: {
        password: '',
        passwordRepeat: ''
      }
    }
  },
  computed: {
    ...mapState([ 'token', 'user' ])
  },
  methods: {
    ...mapActions(['auth', 'showNotification']),
    createForgot () {
      this.$http.post('/api/reset-password', { ...this.forgotForm }).then(() => {
        this.showNotification({ message: 'Saatsime sinu e-posti aadressile lingi parooli muutmiseks. Link kehtib 24 tundi.P', type: 'success' });
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' });
      })
    },
    passwordSubmit () {
      this.$http.post('/api/reset-password/' + this.resetToken, {
        password: this.passwordForm.password,
        passwordRepeat: this.passwordForm.passwordRepeat
      }).then(() => {
        this.showNotification({ message: 'Parool vahetatud', type: 'success' })
        return this.$router.push({ name: 'login' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
  },
  mounted () {
    if (this.user) {
      return this.$router.push({ name: 'home' })
    }
    if (this.$route.query.token) {
      this.resetToken = this.$route.query.token
      this.$http.get('/api/reset-password/' + this.resetToken).then(() => {
        this.showResetForm = true;
      }).catch((err) => {
        this.showNotification({ message: err.response.data.message, type: 'error' });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background:#FFF url('../assets/bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>