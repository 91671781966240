<template>
  <v-main>
    <v-container class="grey lighten-5 pa-0 align-stretch d-flex" style="height:100%" fluid>
      <v-row no-gutters>
        <v-col class="col-12 col-md-6 col-lg-4 align-center d-flex justify-center">
          <div style="width:90%;text-align:left;">
            <h1 class="text-h3 mb-10">Logi sisse</h1>
            <div v-show="showUsernameAndPassword">
              <validation-observer ref="observer" v-slot="{}">
                <form class="mt-10" @submit.prevent="login">
                  <validation-provider  v-slot="{ errors }" name="email" rules="required">
                    <v-text-field v-model="loginForm.email" :error-messages="errors" label="E-maili aadress" required outlined />
                  </validation-provider>
                  <validation-provider  v-slot="{ errors }" name="password" rules="required">
                    <v-text-field v-model="loginForm.password" type="password" :error-messages="errors" label="Parool" required outlined />
                  </validation-provider>
                  <v-btn class="mt-3 mb-2" type="submit" block large color="secondary" tile>Edasi</v-btn>
                  <!--<p class="mb-10">Kui unustasid parooli, vajuta <a href="/forgot-password">siia</a>.</p>-->
                </form>
              </validation-observer>
            </div>
            <v-btn v-show="!showUsernameAndPassword" color="secondary" class="mt-3" @click="showUsernameAndPassword = true" block large tile dark>
              <v-icon class="mr-3">mdi-contacts</v-icon>
              Kasutajanimi ja parool
            </v-btn>
            <v-btn color="#4267B2" class="mt-3" href="/api/auth/facebook" block large tile dark>
              <v-icon class="mr-3">mdi-facebook</v-icon>
              Login with Facebook
            </v-btn>
            <v-btn color="#2867B2" class="mt-3" href="/api/auth/linkedin" block large tile dark>
              <v-icon class="mr-3">mdi-linkedin</v-icon>
              Login with Linkedin
            </v-btn>
            <v-btn color="#4285F4" class="mt-3" href="/api/auth/google" block large tile dark>
              <v-icon class="mr-3">mdi-google</v-icon>
              Login with Google
            </v-btn>
            <!--
            <div class="mt-10">
              <p>Kui sul konto puudub, siis <a @click="register" href="#"><strong>registreeri</strong></a>.</p>
            </div>
            -->
          </div>
        </v-col>
        <v-col class="bg"></v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      showUsernameAndPassword: false,
      loginForm: {
        email: '',
        password: '',
      }
    }
  },
  computed: {
    ...mapState([ 'token', 'user' ])
  },
  methods: {
    ...mapActions(['auth', 'showNotification']),
    register (e) {
      this.$router.push({ name: 'register' })
      e.preventDefault()
    },
    login () {
      this.$http.post('/api/login', { ...this.loginForm }).then((response) => {
        this.authFlow(response.data.token)
      }).catch(err => {
        this.showNotification({ message: err.response.data.message || 'Vale kasutajanimi või parool', type: 'error' });
      })
    },
    authFlow (token) {
      this.auth(token)
      this.showNotification({ message: 'Oled edukalt sisse logitud!', type: 'success'})
      this.$router.push({ name: 'portfolios' })
    }
  },
  mounted () {
    if (this.user) {
      this.$router.push({ name: 'home' })
    }
    if (this.$route.query.token) {
      this.authFlow(this.$route.query.token)
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background:#FFF url('../assets/bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>